<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-tabs
            background-color="#002441"
            active-class="white black--text"
            height="60"
            dark
          >
            <v-tab>Merchant Wise</v-tab>
            <v-tab>Balance Sheet</v-tab>
            <v-tab>Merchnat Transaction</v-tab>


            <!-- This Tab is for Entry -->
            <v-tab-item>
                          <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card class="my-5" elevation="0">
                      <SellerTransactions :render="transactionrender" />
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <!-- This tab is for Transaction -->
            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card class="my-5" elevation="0">
                      <PaymentAllTransactions :render="transactionrender" />
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <!-- This tab is for Merchant Transaction -->
            <v-tab-item>
                          <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card class="my-5" elevation="0">
                      <MerchantTransaction :render="transactionrender" />
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <!-- This tab is for Seller's Transaction -->
            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card class="my-5" elevation="0">
                      <SellerTransactions :render="transactionrender" />
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <!-- this is for all Bank Accounts  -->
            <v-tab-item>
              <v-container fluid>
                <v-row justify="end" class="pa-3">
                  <v-btn
                    color="#002441"
                    elevation="0"
                    @click="add_account_dialog = true"
                    >Add Account</v-btn
                  >
                </v-row>
                <v-row class="mt-0">
                  <v-col cols="12" class="pt-0">
                    <v-card class="my-5" elevation="0">
                      <payment-all-bank-accounts
                        :render="render"
                      ></payment-all-bank-accounts>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>

    <!-- Start Snackbar -->
    <v-snackbar v-model="snackbar" color="white" centered>
      <p class="black--text mb-0">{{ text }}</p>

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- End Snackbar -->
  </v-container>
</template>


<script>
export default {
  data: () => ({


    snackbar: false,
    text: "",
    render: 0,
    transactionrender: 0,

    Order_Commission_array: [],
    transaction_to_accounts: [],
  }),

  components: {
    PaymentCheque: () =>
      import(/* PaymentCheque */ "@/components/payment/PaymentCheque.vue"),
    PaymentBankDeposit: () =>
      import(
        /* PaymentBankDeposit */ "@/components/payment/PaymentBankDeposit.vue"
      ),
    PaymentBankTransfer: () =>
      import(
        /* PaymentBankDeposit */ "@/components/payment/PaymentBankTransfer.vue"
      ),
    PaymentCash: () =>
      import(/* PaymentBankDeposit */ "@/components/payment/PaymentCash.vue"),
    PaymentBKash: () =>
      import(/* PaymentBankDeposit */ "@/components/payment/PaymentBKash.vue"),
    PaymentNagad: () =>
      import(/* PaymentBankDeposit */ "@/components/payment/PaymentNagad.vue"),

    PaymentAllBankAccounts: () =>
      import(/* AllBankAccounts */ "@/components/payment/AllBankAccounts.vue"),

    PaymentAllTransactions: () =>
      import(/* AllBankAccounts */ "@/components/commission/OwnTransaction.vue"),

    MotherTransactions: () =>
      import(
        /* AllBankAccounts */ "@/components/payment/MotherTransactions.vue"
      ),

    SellerTransactions: () =>
      import(
        /* AllBankAccounts */ "@/components/commission/PerOrder.vue"
      ),
      MerchantTransaction: () =>
      import(
        /* AllBankAccounts */ "@/components/commission/MerchantTransaction.vue"
      ),
  },

  methods: {

     initialize() {
        axios.get(`Cartart/individual_merchant_commission/`).then((response) => {
          this.loading = false;
          if (response.data.success) {
            this.orders = response.data.data;
          } else {
            this.orders = [];
          }
        });
      },
    },




  created() {

    this.initialize();

  },
};
</script>


<style>
.custom-header {
  background-color: #e5f1fb;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>

